import { connect } from "react-redux";
import { getMyRole } from "Identity/selectors";
import AtlasChat from "./AtlasChat.tsx";

function mapStateToProps(state, config) {
  const roles = getMyRole(state);

  return {
    roles,
    config
  };
}

export default connect(mapStateToProps)(AtlasChat);
