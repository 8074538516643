export const TELEMETRY_KEYS = {
  APP_LOADED: "app loaded",
  APP_CLOSED: "app closed",
  MESSAGE_SENT: "message sent",
  MESSAGE_RECEIVED: "message received",
  USER_IS_TYPING: "user is typing",
  USER_STOPPED_TYPING: "user stopped typing",
  CLICKED_SEND_BUTTON: "clicked send button",
  ATTACHMENT_UPLOADED: "attachment uploaded",
  ATTACHMENT_FAILED: "attachment failed",
  ATTACHMENT_DELETED: "attachment deleted",
  ATTACHMENT_RECEIVED: "attachment received",
  ATTACHMENT_VIEW: "attachment view",
  ATTACHMENT_DOWNLOAD: "attachment download",
  CONNECT_TO_VIDEO_SESSION: "connect to video session",
  SESSION_STARTED: "session started",
  SESSION_ENDED: "session ended",
  DOWNLOAD_SESSION: "download session",
  SHOW_AUDIO: "show audio",
  USER_ONLINE_STATUS: "user online status",
  TEXT_COPIED: "text copied",
  TEXT_PASTED: "text pasted"
};
