import React, { useCallback, useEffect } from "react";
import { ThemeProvider } from "darker-matter";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Toaster } from "react-hot-toast";
import { useTelemetry } from "ieso-telemetry";
import { TELEMETRY_KEYS } from "config/telemetry.ts";
import { useUserId } from "hooks/useUserId.ts";
import { getTherapyRoomId } from "utils/common";
import Chat from "../Chat/index.ts";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const shouldLogTelemetry = ({ config }) => {
  return config?.log_copy_paste_actions ?? false;
};

const getPayload = ({ roles, size, userId, therapyRoomId }) => {
  const isPatient =
    roles === undefined || roles.length === 0 || roles.includes("Member");
  const isClinician =
    roles !== undefined && roles.length > 0 && roles.includes("Agent");
  const isGlobalAdmin =
    roles !== undefined && roles.length > 0 && roles.includes("GlobalAdmin");

  return {
    userId,
    therapyRoomId,
    size,
    isPatient,
    isClinician,
    isGlobalAdmin
  };
};

const AtlasChat = ({ roles, config }) => {
  const telemetry = useTelemetry();
  const userId = useUserId();
  const therapyRoomId = getTherapyRoomId();

  const onCopyHandler = useCallback(() => {
    const selection = window.getSelection();
    const copiedText = selection.toString();

    const payload = getPayload({
      roles,
      userId,
      therapyRoomId,
      size: copiedText.length
    });

    telemetry.trackEvent(TELEMETRY_KEYS.TEXT_COPIED, payload);

    /* eslint-disable no-console */
    if (shouldLogTelemetry(config)) {
      console.log("Telemetry on copying", payload);
    }
  }, [roles, telemetry]);

  const onPasteHandler = useCallback(
    (e: ClipboardEvent) => {
      const { clipboardData } = e;
      const contentLength = clipboardData.getData("text/plain").length;

      const payload = getPayload({
        roles,
        userId,
        therapyRoomId,
        size: contentLength
      });

      telemetry.trackEvent(TELEMETRY_KEYS.TEXT_PASTED, payload);

      if (shouldLogTelemetry(config)) {
        console.log("Telemetry on pasting", payload);
      }
    },
    [roles, telemetry]
  );

  useEffect(() => {
    window.addEventListener("copy", onCopyHandler);
    window.addEventListener("paste", onPasteHandler);

    return () => {
      window.removeEventListener("copy", onCopyHandler);
      window.removeEventListener("paste", onPasteHandler);
    };
  }, [onCopyHandler, onPasteHandler]);

  return (
    <ThemeProvider
      extend={{ colors: { actionDestructive: { rest: "#FF4B4B" } } }}
    >
      <QueryClientProvider client={queryClient}>
        <Chat />
        <Toaster
          position="bottom-right"
          toastOptions={{ duration: Infinity }}
        />
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default AtlasChat;
